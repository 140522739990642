import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/NavBar'
import './learning-center.css'
import ebook from '../../assets/images/ebook.png'
import { Box, Chip, Tab, Tabs } from '@mui/material'
import newsLetter1 from '../../assets/images/newsletter-1.png'
import posters1 from '../../assets/images/posters-1.png'
import poshcast from '../../assets/images/poshcast.png'
import connectingVoice from '../../assets/images/connecting-voice.png'
import RightButton from '../../components/right-button/RightButton'
import { mediaByName, text } from '../../services/pageFunctions'
import { getPageBySlug } from '../../services/pageService'

const LearningCenter = () => {
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getPageBySlug('learning-center').then(result => {
      setPage(result.data.data);
    });
  }, [])

  return (
    <div>
      <NavBar />
      <div className='container'>
        <div className='p-3'></div>
        <div className='row'>
          <div className='col-sm-6 d-flex align-items-center'>
            <div>
              <div className='text-danger'>{text(page, 0)}</div>
              <div className='heading'>{text(page, 1)}</div>
              <p className='text-justify'>{text(page, 2)}</p>
            </div>
          </div>
          <div className='col-sm-6'>
            <img src={mediaByName(page, 'floatingGirl')} style={{ maxWidth: '100%' }} />
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <div className='bg-dark'>
        <div className='container'>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='LEGAL RESOURCES' className='text-white' />
              <Tab label='CREATIVE RESOURCES' className='text-white' />
              <Tab label='EVENTS' className='text-white' />
            </Tabs>
          </Box>
        </div>
        <div className='container text-white'>
          <div role='tabpanel' hidden={value != 0}>
            <div className='p-3'></div>
            <div className='row'>
              <div className='col-sm-6'>
                <h1>Sexual harassment of women at workplace (prevention, prohibition & redressal) Act 2013</h1>
                <div>12 September 2015</div>
                <a href='#' className='text-primary'>E-book</a>
                <p> Through our monthly podcasts, newsletters, periodical events, awareness posters and more, we employ various creative platforms and methods to engage and help sensitise, both at individual and organizational levels.</p>
                <div className='d-flex align-items-center gap-2'>
                  <p className='pt-2'>Read</p>
                  <RightButton />
                </div>
              </div>
              <div className='col-sm-6'>
                <img src={ebook} style={{ maxWidth: '100%' }} />
              </div>
            </div>
            <div className='p-3'></div>
            <h3>E-Book Collection</h3>
            <div className='p-2'></div>
            <div className='row'>
              <div className='col-sm-4'>
                <img src={ebook} style={{ width: '100%' }} />
                <div className='p-1'></div>
                <p>E-book by Sasha</p>
                <div>October 2, 2022</div>
              </div>
              <div className='col-sm-4'>
                <img src={ebook} style={{ width: '100%' }} />
                <div className='p-1'></div>
                <p>E-book by Sasha</p>
                <div>October 2, 2022</div>
              </div>
              <div className='col-sm-4'>
                <img src={ebook} style={{ width: '100%' }} />
                <div className='p-1'></div>
                <p>E-book by Sasha</p>
                <div>October 2, 2022</div>
              </div>
            </div>
            <div className='p-3'></div>
            <div className='row'>
              <div className='col-sm-4'>
                <img src={ebook} style={{ width: '100%' }} />
                <div className='p-1'></div>
                <p>E-book by Sasha</p>
                <div>October 2, 2022</div>
              </div>
              <div className='col-sm-4'>
                <img src={ebook} style={{ width: '100%' }} />
                <div className='p-1'></div>
                <p>E-book by Sasha</p>
                <div>October 2, 2022</div>
              </div>
            </div>
          </div>
          <div role='tabpanel' hidden={value != 1}>
            <div className='p-3'></div>
            <div className='row'>
              <div className='col-sm-6'>
                <h1>Newsletter</h1>
                <div className='p-2'></div>
                <p>VOICE - The SASHA Newsletter publishes articles authored by SASHA members and other prominent individuals on topics like legal updates, case law analysis, compliance aspects, social issues related to PoSH etc.</p>
                <div className='p-2'></div>
                <div className='d-flex align-items-center gap-2'>
                  <p className='pt-2'><strong>Voice SASHA - Substack</strong></p>
                  <RightButton />
                </div>
              </div>
              <div className='col-sm-6'>
                <img src={newsLetter1} style={{ maxWidth: '100%' }} />
                <div className='p-1'></div>
                <div className='d-flex align-items-center gap-2'>
                  <Chip color='error' label='New' className='ps-3 pe-3' />
                  <span>Issue 21</span>
                  <span>|</span>
                  <span>12 September 2022</span>
                </div>
              </div>
            </div>
            <div className='p-3'></div>
            <div className='row'>
              <div className='col-sm-6'>
                <h1>Posters</h1>
                <div className='p-2'></div>
                <p>Download our free ready-to-print posters that spread awareness on sexual harassment at the workplace and PoSH. We're sure the quirk will stay on and do wonders as a subtle reminder at your workplace.</p>
                <div className='p-2'></div>
                <div className='d-flex align-items-center gap-2'>
                  <p className='pt-2'><strong>View all</strong></p>
                  <RightButton />
                </div>
              </div>
              <div className='col-sm-6'>
                <img src={posters1} style={{ maxWidth: '100%' }} />
                <div className='p-1'></div>
                <div className='d-flex align-items-center gap-2'>
                  <Chip color='error' label='New' className='ps-3 pe-3' />
                  <span>2022 Edition</span>
                </div>
              </div>
            </div>
            <div className='p-3'></div>
            <div className='row'>
              <div className='col-sm-6'>
                <h1>PoSHcast</h1>
                <div className='p-2'></div>
                <p>VOICE - The SASHA PoSHCast is your go to podcast for all things PoSH. Each month, we explore topics beyond the legal mandate of PoSH by interacting with leading PoSH experts, legal consultants, HR personnel and more.</p>
                <div className='p-2'></div>
                <div><a href='#'>Spotify</a></div>
                <div><a href='#'>Apple music</a></div>
                <div><a href='#'>Soundcloud</a></div>
                <div><a href='#'>Youtube</a></div>
              </div>
              <div className='col-sm-6'>
                <img src={poshcast} style={{ maxWidth: '100%' }} />
                <div className='p-1'></div>
                <div className='d-flex align-items-center gap-2'>
                  <Chip color='error' label='New' className='ps-3 pe-3' />
                  <span>Voice-The Sasha PoSHcast</span>
                  <span>|</span>
                  <span className='text-danger'>Episode 13</span>
                </div>
              </div>
            </div>
            <div className='p-3'></div>
          </div>
          <div role='tabpanel' hidden={value != 2}>
            <div className='p-3'></div>
            <div className='row'>
              <div className='col-sm-6'>
                <h1>Connecting Voice</h1>
                <div className='p-2'></div>
                <h4>A Sasha Circle</h4>
                <div className='p-2'></div>
                <p className='text-primary'>CONFERENCE</p>
                <div className='p-2'></div>
                <p>SASHA Events: Come and be a part of SASHA events to share your PoSH related experience or learn something new. Come, be a part of the community.</p>
                <div className='d-flex align-items-center gap-2'>
                  <p className='pt-2'><strong>Register</strong></p>
                  <RightButton />
                </div>
              </div>
              <div className='col-sm-6'>
                <img src={connectingVoice} style={{ maxWidth: '100%' }} />
                <div className='p-2'></div>
                <div className='d-flex align-items-center'>
                  <div>Wed, Aug 19, 7:30PM — Wed, Aug 19, 8:30PM</div>
                </div>
                <div className='p-2'></div>
                <h5>Guests</h5>
                <span>Kanti Joshi</span>, <span>Jessamine Mathew</span>
              </div>
            </div>
            <div className='p-4'></div>
            <Box sx={{ borderBottom: 1, borderColor: 'red' }} className='pb-3'>
              <h3>Upcoming Events</h3>
            </Box>
            <div className='p-5'></div>
            <div className='row'>
              <div className='col-sm-3'>
                <h1>25</h1>
                <h5>SEPTEMBER</h5>
              </div>
              <div className='col-sm-6'>
                <p>CONNECTING VOICE - A SASHA CIRCLE</p>
                <div>Wed, Aug 19, 7:30PM — Wed, Aug 19, 8:30PM</div>
              </div>
              <div className='col-sm-3'>
                <div className='d-flex align-items-center gap-2'>
                  <p className='pt-2'>Register</p>
                  <RightButton />
                </div>
              </div>
            </div>
            <div className='p-5'></div>
            <div className='row'>
              <div className='col-sm-3'>
                <h1>27</h1>
                <h5>SEPTEMBER</h5>
              </div>
              <div className='col-sm-6'>
                <p>CONNECTING VOICE - A SASHA CIRCLE</p>
                <div>Wed, Aug 19, 7:30PM — Wed, Aug 19, 8:30PM</div>
              </div>
              <div className='col-sm-3'>
                <div className='d-flex align-items-center gap-2'>
                  <p className='pt-2'>Register</p>
                  <RightButton />
                </div>
              </div>
            </div>
            <div className='p-5'></div>
            <div className='row'>
              <div className='col-sm-3'>
                <h1>30</h1>
                <h5>SEPTEMBER</h5>
              </div>
              <div className='col-sm-6'>
                <p>CONNECTING VOICE - A SASHA CIRCLE</p>
                <div>Wednesday, NOV 30, 7:30PM — Thrusday, DEC 1, 8:30PM</div>
              </div>
              <div className='col-sm-3'>
                <div className='d-flex align-items-center gap-2'>
                  <p className='pt-2'>Register</p>
                  <RightButton />
                </div>
              </div>
            </div>
            <div className='p-3'></div>
          </div>
        </div>
        <div className='p-5'></div>
      </div>
      <Footer backgroundColor='#0D131A' textColor='white' />
    </div>
  )
}

export default LearningCenter