import React, { useEffect, useState } from 'react'
import NavBar from '../../components/navbar/NavBar'
import './home.css'
import Footer from '../../components/footer/footer'
import { getPageBySlug } from '../../services/pageService';
import RightButton from '../../components/right-button/RightButton'
import { useViewport } from '../../hooks/viewport-provider'
import { text, mediaByName } from '../../services/pageFunctions';

const Home = () => {
  const [page, setPage] = useState(null);
  const { width } = useViewport();

  useEffect(() => {
    getPageBySlug('home').then(result => {
      setPage(result.data.data);
    });
  }, []);

  return (
    <div>
      <div className='full-screen'>
        <NavBar />
        <div className='hero-container'>
          <div className='container cta' style={{ backgroundImage: `url('${mediaByName(page, 'big-bubble')}')` }}>
            <div>
              <h1 className='heading'>{text(page, 0)}</h1>
              <p className='subheading'>{text(page, 1)}</p>
            </div>
          </div>
        </div>
      </div>
      {width > 768 ?
        <div>
          <div>
            <div className='container'>
              <div className='row'>
                <div className='pt-5'></div>
                <div className='col-sm-5 mt-5 mb-5'>
                  <p className='text-danger'>{text(page, 2)}</p>
                  <h1 className='heading'>{text(page, 3)}</h1>
                  <p>{text(page, 4)}</p>
                  <br />
                </div>
                <div className='col-sm-7'>
                  <img src={mediaByName(page, 'ellipse115')} style={{ position: 'absolute', width: width > 768 ? null : '100%' }} />
                  <img src={mediaByName(page, 'path512')} style={{ position: 'absolute', width: width > 768 ? null : '100%' }} />
                </div>
              </div>
            </div>
          </div>
          <div className='mb-5'>
            <div className='container'>
              <div>{text(page, 5)}</div>
              <div className='d-flex align-items-center gap-2'>
                <p className='pt-2'>{text(page, 6)}</p>
                <RightButton />
              </div>
            </div>
          </div>
          <div>
            <div style={{ width: 0 }}></div>
            <img src={mediaByName(page, 'awareness')} style={{ width: '90%', marginLeft: '10%' }} />
          </div>
        </div> :
        <div>
          <div>
            <div className='container'>
              <div className='row'>
                <div className='pt-5'></div>
                <p className='text-danger'>{text(page, 2)}</p>
                <h1 className='heading'>{text(page, 3)}</h1>
              </div>
            </div>
            <img src={mediaByName(page, 'awareness')} style={{ width: '100%', maxWidth: '100%' }} />
            <div className='container'>
              <div>
                <p>{text(page, 4)}</p>
                <br />
              </div>
            </div>
          </div>
          <div className='section1-container mb-5'>
            <div className='container'>
              <div>{text(page, 5)}</div>
              <div className='d-flex align-items-center gap-2'>
                <p className='pt-2'>{text(page, 6)}</p>
                <RightButton />
              </div>
              <div className='section1'>
                <div className='container' style={{ height: '100%' }}>
                </div>
              </div>
              <div className='col-sm-7 pb-5'>
                <img src={mediaByName(page, 4)} style={{ position: 'absolute', width: '50px' }} />
              </div>
            </div>
          </div>
        </div>}
      <div className='container mb-5'>
        <div className='row'>
          <div className='col-md-6'>
            <p className='text-danger'>{text(page, 7)}</p>
            <h1 className='heading'>{text(page, 8)}</h1>
            <p>{text(page, 9)}<strong>{text(page, 10)}</strong></p>
            <p className='mb-5'>{text(page, 11)}</p>
            <p>{text(page, 12)}</p>
            <div className='d-flex align-items-center gap-2'>
              <p className='pt-2'><strong>{text(page, 13)}</strong></p>
              <RightButton />
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'right' }}>
            {width > 576 ? <div style={{ position: 'relative', right: 0 }}><img src={mediaByName(page, 'path512')} style={{ width: '50%' }} /></div> : <div></div>}
            <img src={mediaByName(page, 'complaints')} style={{ width: '100%' }} />
          </div>
        </div>
      </div>
      <div className='container mb-5'>
        <div className='row'>
          <div className='col-md-6'>
            <p className='text-danger'>{text(page, 14)}</p>
            <h1 className='heading'>{text(page, 15)}</h1>
            <p className='mb-5'>{text(page, 16)}<strong>{text(page, 17)}</strong></p>
            <p>{text(page, 18)}</p>
            <div className='d-flex align-items-center gap-2'>
              <p className='pt-2'><strong>{text(page, 19)}</strong></p>
              <RightButton />
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'right' }}>
            <img src={mediaByName(page, 'bubblesToBurst')} style={{ width: '100%' }} />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6'>
            <img src={mediaByName(page, 'posterGroup')} style={{ width: '100%' }} />
          </div>
          <div className='col-sm-6 d-flex align-items-center justify-content-center' style={{ flexDirection: 'column' }}>
            <div className='p-3'></div>
            <table>
              <tr>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'facebookIcon')} /></td>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'stitcherIcon')} /></td>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'linkedinIcon')} /></td>
              </tr>
              <tr>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'twitterIcon')} /></td>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'spotifyIcon')} /></td>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'someSocialIcon')} /></td>
              </tr>
              <tr>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'instagramIcon')} /></td>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'soundcloudIcon')} /></td>
                <td style={{ padding: '20px' }}><img src={mediaByName(page, 'youtubeIcon')} /></td>
              </tr>
            </table>
            <p className='pt-5'>{text(page, 20)}<strong className='text-danger'>{text(page, 21)}</strong>{text(page, 22)}</p>
          </div>
        </div>
      </div>
      <div className='pt-5 pb-5'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6'>
            <img src={mediaByName(page, 'girls')} style={{ width: '100%' }} />
          </div>
          <div className='col-sm-6 d-flex align-items-center justify-content-center'>
            <div>
              <p>{text(page, 23)}</p>
              <div className='pb-5'></div>
              <p>{text(page, 24)}</p>
              <div className='d-flex align-items-center gap-2'>
                <p className='pt-2'><strong>{text(page, 25)}</strong></p>
                <RightButton />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-5 pb-5'></div>
      <div className='bg-dark'>
        <div className='container'>
          <div className='pt-5 pb-5'></div>
          <h1 className='heading text-danger'>{text(page, 26)}</h1>
          <h5 className='text-white col-sm-7'>{text(page, 27)}</h5>
          <div className='heading col-sm-7 text-white'>{text(page, 28)}<br />{text(page, 29)}<br />{text(page, 30)}<span style={{ fontSize: '0.3em', marginLeft: '50px' }}>{text(page, 31)}</span></div>
          <div className='pt-5 pb-5'></div>
        </div>
      </div>
      <div className='bg-dark'>
        <Footer backgroundColor='#0D131A' textColor='white' />
      </div>
    </div>
  )
}

export default Home