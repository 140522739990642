import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/footer';
import NavBar from '../../components/navbar/NavBar';
import NewLabel from '../../components/new-label/NewLabel';
import RightButton from '../../components/right-button/RightButton';
import image151 from '../../assets/images/image 15 (1).png'
import frame828 from '../../assets/images/Frame 828.png'

const Article = () => {
  const [currentTab, setCurrentTab] = useState('recent');
  let { slug } = useParams();

  return (
    <div>
      <NavBar />
      <div className='bg-dark'>
        <div className='container' style={{ overflowX: 'auto' }}>
          <div className='text-white top-nav'>
            <a href="#" className={currentTab === 'recent' ? 'active' : ''} onClick={() => setCurrentTab('recent')}>RECENT</a>
            <a href="#" className={currentTab === 'workplace' ? 'active' : ''} onClick={() => setCurrentTab('workplace')}>WORKPLACE</a>
            <a href="#" className={currentTab === 'policies' ? 'active' : ''} onClick={() => setCurrentTab('policies')}>POLICIES</a>
            <a href="#" className={currentTab === 'law-mandates' ? 'active' : ''} onClick={() => setCurrentTab('law-mandates')}>LAW MANDATES</a>
            <a href="#" className={currentTab === 'internal-committee' ? 'active' : ''} onClick={() => setCurrentTab('internal-committee')}>INTERNAL COMMITEE</a>
          </div>
        </div>
      </div>
      <div className='p-2'></div>
      <div className='container'>
        <div className='d-flex gap-1 align-items-center'><div style={{ marginTop: '-10px' }}>Go Back</div><RightButton href='/blog' /></div>
        <h1>Key Elements of Workplace Sexual Harassment</h1>
        <div className='p-2'></div>
        <div className='d-flex align-items-center gap-3'>
          <NewLabel /><span>12 October, 2022</span>
        </div>
        <div className='p-2'></div>
        <img src={image151} style={{ width: '100%', maxWidth: '100%' }} />
        <div className='p-2'></div>
        <div className='d-flex gap-3'>
          <div className='d-flex flex-column gap-2 align-items-center'>
            <div style={{ backgroundColor: 'lightblue', borderRadius: '3px', width: 45, height: 45 }}></div>
            <div>Share</div>
          </div>
          <div className='d-flex flex-column gap-2 align-items-center'>
            <div style={{ backgroundColor: 'lightblue', borderRadius: '3px', width: 45, height: 45 }}></div>
            <div>Copy Link</div>
          </div>
        </div>
        <div className='p-2'></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ultricies leo et diam interdum efficitur. Proin augue metus, viverra eget egestas et, placerat cursus erat. Maecenas sit amet diam sit amet odio tincidunt efficitur. Curabitur varius diam volutpat mauris pulvinar molestie. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In pretium turpis in ex pretium, at consectetur diam gravida. Nulla porta feugiat mi sit amet fringilla. Sed consequat urna id condimentum finibus. Maecenas non hendrerit metus. Ut gravida varius odio eu luctus. Vivamus diam lectus, maximus at semper in, egestas ac nibh. Sed nec ornare urna.</p>
        <p>Donec eget neque sit amet nisl fringilla semper. Sed ac mauris elit. Etiam tellus nulla, ullamcorper vel tincidunt ac, vestibulum in est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam sed odio id nunc sagittis facilisis vitae aliquam nulla. Etiam blandit, sem in malesuada blandit, mauris leo rhoncus dui, nec lacinia magna elit sollicitudin nulla. Pellentesque eu lectus libero. Quisque facilisis pharetra dui a efficitur. Pellentesque at dignissim risus. Nunc ornare, purus eget viverra vulputate, metus tortor viverra est, a blandit turpis arcu at metus. In hac habitasse platea dictumst. Quisque et luctus nisi. Duis consequat dolor diam, tincidunt volutpat neque viverra sit amet. Cras nec lectus elit. Sed eget sapien nec arcu venenatis pellentesque.</p>
        <p>Nullam eget commodo nisi. Vestibulum condimentum nibh mi, ac vulputate lectus accumsan vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam ut sem elementum est ornare consectetur. Sed ac aliquet ipsum. Morbi dictum sem non magna blandit, a eleifend dui gravida.</p>
        <p>Fusce ac ornare nisl, vitae condimentum purus. Donec feugiat velit eu felis bibendum, non vestibulum augue interdum. Nam non consectetur arcu. Nam venenatis dui quam, eu bibendum nunc pulvinar elementum. Nulla facilisi. Aenean aliquam accumsan magna, eget vestibulum lorem sollicitudin sed. Curabitur eu sem vel nulla feugiat semper. Ut consequat lacus ut tellus accumsan, vitae dapibus quam tincidunt. Pellentesque fermentum ex arcu, at varius purus molestie quis. Nam ut dignissim velit. Sed sem felis, lobortis et leo nec, pretium mattis ex. Maecenas eget tempor nisl, in mollis dolor. Nam finibus ante a nulla feugiat semper. Vivamus eget orci nulla.</p>
        <p>Aliquam semper ipsum sed lectus ornare molestie. Etiam ut purus non magna volutpat pellentesque. Nullam non nulla condimentum, pulvinar tortor sed, suscipit est. Vestibulum laoreet diam eu erat tristique commodo. Cras nec eros iaculis, semper purus at, imperdiet nibh. Nunc eget condimentum felis, tempus iaculis ligula. Maecenas eget lorem nunc. Nulla tristique, neque a sagittis maximus, massa massa consequat eros, ac dictum lectus lectus et leo. Curabitur rhoncus euismod nibh eu cursus. Ut gravida a sapien eu mattis. Aenean tincidunt lectus eu lorem fringilla, in bibendum erat sollicitudin.</p>
        <div className='p-3'></div>
        <h4 className='text-danger'>Sources</h4>
        <div className='p-2'></div>
        <a href="#" className='text-danger small-text'>Handbook on Sexual Harassment of Women at Workplace</a>
        <div className='p-3'></div>
      </div>
      <div className='p-1'></div>
      <div style={{ backgroundColor: 'rgba(13, 19, 26, 0.02)' }}>
        <div className='p-3'></div>
        <div className='container'>
          <h3>September 2022 blogs</h3>
          <div className='p-2'></div>
          <div className='row'>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <Footer backgroundColor='#0D131A' textColor='white' />
    </div>
  )
}

export default Article