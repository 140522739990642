import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/NavBar'
import './services.css'
import { text, mediaByName } from '../../services/pageFunctions'
import { getPageBySlug } from '../../services/pageService'

const Services = () => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    getPageBySlug('services').then(result => {
      setPage(result.data.data);
    });
  }, []);

  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div className='container'>
        <div className='p-3'></div>
        <div className='row'>
          <div className='col-sm-6 d-flex align-items-center'>
            <div>
              <div className='p-3'></div>
              <div className='text-danger'>{text(page, 0)}</div>
              <h1 className='stylish'>{text(page, 1)}</h1>
              <p>{text(page, 2)}<strong>{text(page, 3)}</strong></p>
            </div>
          </div>
          <div className='col-sm-6 d-flex align-items-center'>
            <img src={mediaByName(page, 'hammerGroup')} style={{ maxWidth: '100%' }} />
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <div className='p-3'></div>
      <div className='p-3' style={{ backgroundColor: 'rgba(238, 48, 34, 0.05)' }}>
        <div className='container'>
          <div className='p-3'></div>
          <div className='row'>
            <div className='col-sm-6 d-flex align-items-center'>
              <div>
                <div className='p-3'></div>
                <div className='text-danger'>{text(page, 4)}</div>
                <h1 className='stylish'>{text(page, 5)}</h1>
                <p>{text(page, 6)}</p>
                <div className='p-3'></div>
              </div>
            </div>
            <div className='col-sm-6 d-flex align-items-center'>
              <img src={mediaByName(page, 'paperBoats')} style={{ maxWidth: '100%' }} />
            </div>
          </div>
          <div className='p-3'></div>
        </div>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6 d-flex align-items-center'>
            <div>
              <div className='text-danger'>{text(page, 7)}</div>
              <h1 className='stylish'>{text(page, 8)}</h1>
              <p>{text(page, 9)}</p>
            </div>
          </div>
          <div className='col-sm-6'>
            <img src={mediaByName(page, 'teacherWhiteboard')} style={{ maxWidth: '100%' }} />
          </div>
        </div>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <div className='rounded bg-dark p-5'>
          <p className='text-white'>{text(page, 10)}</p>
          <div className='p-3'></div>
          <div className='row gx-0'>
            <div className='col'>
              <img src={mediaByName(page, 'training1')} style={{ maxWidth: '100%' }} />
            </div>
            <div className='col'>
              <img src={mediaByName(page, 'training2')} style={{ maxWidth: '100%' }} />
            </div>
            <div className='col'>
              <img src={mediaByName(page, 'training3')} style={{ maxWidth: '100%' }} />
            </div>
            <div className='col'>
              <img src={mediaByName(page, 'training4')} style={{ maxWidth: '100%' }} />
            </div>
            <div className='col'>
              <img src={mediaByName(page, 'training5')} style={{ maxWidth: '100%' }} />
            </div>
            <div className='col'>
              <img src={mediaByName(page, 'training6')} style={{ maxWidth: '100%' }} />
            </div>
            <div className='col'>
              <img src={mediaByName(page, 'training7')} style={{ maxWidth: '100%' }} />
            </div>
          </div>
        </div>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <div className='p-3'></div>
        <div className='row'>
          <div className='col-sm-6 d-flex align-items-center'>
            <img src={mediaByName(page, 'postboxGroup')} style={{ maxWidth: '100%' }} />
          </div>
          <div className='col-sm-6 d-flex align-items-center'>
            <div>
              <div className='text-danger'>{text(page, 11)}</div>
              <h1 className='stylish'>{text(page, 12)}</h1>
              <p>{text(page, 13)}</p>
            </div>
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <div className='container mt-5 bg-dark rounded text-white pt-5 ps-5 pe-5 pb-3'>
          <div className='row'>
            <div className='col-sm-4 mb-4 d-flex justify-content-center'>
              <div>
                <div>
                  <img src={mediaByName(page, 'mailIcon')} /><span className='ms-2'>Kanti Joshi</span>
                </div>
                <div>Kanti@sashaindia.com</div>
              </div>
            </div>
            <div className='col-sm-4 mb-4 d-flex justify-content-center'>
              <div>
                <div>
                  <img src={mediaByName(page, 'phoneIcon')} /><span className='ms-2'>Kanti Joshi</span>
                </div>
                <div>+91 98450 96503</div>
              </div>
            </div>
            <div className='col-sm-4 mb-4 d-flex justify-content-center'>
              <div>
                <div>
                  <img src={mediaByName(page, 'locationIcon')} /><span className='ms-2'>SASHA</span>
                </div>
                <div>Bangalore</div>
              </div>
            </div>
          </div>
        </div>
        <div className='pt-5 pb-5'></div>
      </div>
      <div className='p-3'></div>
      <Footer backgroundColor='#000000' textColor='#ffffff' />
    </div>
  )
}

export default Services