const text = (page, index) => {
  return page && page.textEntries[index] ? page.textEntries[index].text : '';
}

const media = (page, index) => {
  return `${process.env.REACT_APP_API_ENDPOINT}/file/${page && page.media[index] ? page.media[index].file[0]._id: ''}`;
}

const mediaByName = (page, name) => {
  let filtered = page?.media?.filter(media => media.name === name);
  return filtered?.length > 0 ? `${process.env.REACT_APP_API_ENDPOINT}/file/${filtered[0].file[0]._id}` : '';
}

const mediaUrl = (id) => {
  if (id) {
    return `${process.env.REACT_APP_API_ENDPOINT}/file/${id}`;
  }
  else {
    return null;
  }
}

export { text, media, mediaByName, mediaUrl };