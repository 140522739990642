import React from 'react'
import RightButton from '../right-button/RightButton';
import './footer.css';

const Footer = (props) => {
  return (
    <div>
      <div className='pt-5 pb-5 footer'>
        <div className='container'>
          <div className="row">
            <div className='col-sm-6 d-flex-col' style={{ alignItems: 'center' }}>
              <span style={{ color: `${props.textColor}` }}>Newsletter</span>
              <h5 style={{ color: `${props.textColor}` }}>Want to learn more about</h5>
              <h3 style={{ color: '#ff0000' }}>SEXUAL HARASSMENT</h3>
              <form className='col-sm-6 mt-5'>
                <div className="mb-3 d-flex gap-2">
                  <input type="email" className="form-control p-3" id="exampleFormControlInput1" placeholder="Enter your e-mail ID to subscribe" />
                  <RightButton className='pt-2' />
                </div>
              </form>
            </div>
            <div className='col-sm-3'>
              <ul className='nav-list'>
                <li><a href='#'>Home</a></li>
                <li><a href='#'>Learning Center</a></li>
                <li><a href='#'>About Us</a></li>
                <li><a href='#'>Contact Us</a></li>
              </ul>
              <ul className='nav-list mt-4'>
                <li><a href='#'>FAQ's</a></li>
                <li><a href='#'>Policy</a></li>
                <li><a href='#'>Creative Package</a></li>
              </ul>
            </div>
            <div className='col-sm-3'>
              <ul className='nav-list'>
                <li><a href='#'>Linkedin</a></li>
                <li><a href='#'>Instagram</a></li>
                <li><a href='#'>Facebook</a></li>
                <li><a href='#'>Youtube</a></li>
                <li><a href='#'>Twitter</a></li>
                <li><a href='#'>Soundcloud</a></li>
                <li><a href='#'>Spotify</a></li>
                <li><a href='#'>Apple product</a></li>
                <li><a href='#'>Substack</a></li>
                <li><a href='#'>Stitcher</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='sub-footer'>
        <div className='p-2'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4'>
              <a href='terms-and-conditions'>Terms &amp; Conditions</a>
            </div>
            <div className='col-sm-4'>
              All rights reserved – 2020 (C) SASHA
            </div>
            <div className='col-sm-4'>
              Website Design  by Intesar Sheikh
            </div>
          </div>
        </div>
        <div className='p-2'></div>
      </div>
    </div>
  )
}

export default Footer