import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/NavBar'
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { text, mediaByName } from '../../services/pageFunctions';
import { getPageBySlug } from '../../services/pageService'
import { useViewport } from '../../hooks/viewport-provider'

const Contact = () => {
  const [page, setPage] = useState(null);
  const { width } = useViewport();

  useEffect(() => {
    getPageBySlug('contact').then(result => {
      setPage(result.data.data);
    });
  }, []);

  return (
    <div>
      <div>
        <NavBar />
        <div className='container'>
          <div className='pt-5'></div>
          <div className='row'>
            <div className='col-sm-5 d-flex align-items-center justify-contents-center'>
              <div>
                <p className='text-danger'>{text(page, 0)}</p>
                <div className='heading'>{text(page, 1)}</div>
                <p>{text(page, 2)}</p>
              </div>
            </div>
            <div className='col-sm-7 text-center'>
              <img src={mediaByName(page, 'contact')} style={{ maxWidth: '100%' }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='pt-5 pb-5'></div>
        <div className='container'>
          <div className='text-danger'>{text(page, 3)}</div>
          <div className='heading'>{text(page, 4)}</div>
        </div>
        <div className='pt-5'></div>
      </div>
      <div className='container pb-5'>
        <div className='rounded bg-white border-1 shadow pt-5 pb-5'>
          <div className='row mt-5 mb-5'>
            <div className='col-sm-2'>
              <img src={mediaByName(page, 'mailbox')} />
            </div>
            <div className='col-sm-5 p-5'>
              <div className='text-danger mb-3'><strong>Send us a message</strong></div>
              <div className='mb-4'>
                <TextField label="Name" variant="standard" placeholder='Enter your name here' style={{ width: '100%' }} />
              </div>
              <div className='mb-4'>
                <TextField label='Email' variant='standard' placeholder='Enter your E-mail ID here' style={{ width: '100%' }} />
              </div>
              <div className='mb-4'>
                <TextField label='Phone Number' variant='standard' placeholder='+91 0000 0000 00' style={{ width: '100%' }} />
              </div>
              <div className='mb-4'>
                <TextField label='Company' variant='standard' placeholder='Your company name here' style={{ width: '100%' }} />
              </div>
            </div>
            <div className='col-sm-5 p-5'>
              <br />
              <br />
              <div><strong>Preferred Mode of communication</strong></div>
              <RadioGroup defaultValue='Phone'>
                <div className='d-flex align-items-center' style={{ gap: '50px' }}>
                  <FormControlLabel value="Phone" control={<Radio />} label="Phone" />
                  <FormControlLabel value="Email" control={<Radio />} label="Email" />
                </div>
              </RadioGroup>
              <div className='mb-4'>
                <TextField label='Message' placeholder='Message to be no more than 300 characters' multiline={true} variant='standard' rows='4' style={{ width: '100%' }} />
              </div>
              <div className='mb-4'>
                <FormControlLabel control={<Checkbox />} label="Subscribe to Sasha Newletter" />
              </div>
              <button className='btn btn-danger rounded-pill ps-5 pe-5'>SUBMIT</button>
            </div>
          </div>
          <div className='mb-5'></div>
          <div className='row'>
            <div className='col-sm-2'></div>
            <div className='col-sm-5 p-5'>
              <div className='text-danger mb-5'><strong>Send us a message</strong></div>
              <div>
                <img src={mediaByName(page, 'mailIcon')} /> <span>Kanti Joshi</span>
              </div>
              <div className='mb-4 ms-4'><strong>Kanti@sashaindia.com</strong></div>
              <div>
                <img src={mediaByName(page, 'phoneIcon')} /> <span>Kanti Joshi</span>
              </div>
              <div className='mb-4 ms-4'><strong>+91 98450 96503</strong></div>
              <div>
                <img src={mediaByName(page, 'locationIcon')} /> <span>SASHA</span>
              </div>
              <div className='mb-4 ms-4'><strong>Bangalore</strong></div>
            </div>
            <div className='col-sm-5'>
              <img src={mediaByName(page, 'call')} style={{ maxWidth: '100%' }} />
            </div>
          </div>
        </div>
        <div className='mt-3'></div>
        <div className='p-3'>Go back <span className='text-danger'>HOME</span></div>
      </div>
      <Footer backgroundColor='#000000' textColor='#ffffff' />
    </div>
  )
}

export default Contact