import React, { useState } from 'react'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/NavBar'
import './blog.css'
import image15 from '../../assets/images/image 15.png'
import RightButton from '../../components/right-button/RightButton'
import frame828 from '../../assets/images/Frame 828.png'
import group817 from '../../assets/images/Group 817.png'
import rectangle394 from '../../assets/images/Rectangle 394.png'
import maskGroup1 from '../../assets/images/Mask group-1.png'
import maskGroup2 from '../../assets/images/Mask group-2.png'
import maskGroup3 from '../../assets/images/Mask group-3.png'
import maskGroup4 from '../../assets/images/Mask group-4.png'

const Blog = () => {
  const [currentTab, setCurrentTab] = useState('recent');

  return (
    <div>
      <NavBar />
      <div className='bg-dark'>
        <div className='container' style={{ overflowX: 'auto' }}>
          <div className='text-white top-nav'>
            <a href="#" className={currentTab === 'recent' ? 'active' : ''} onClick={() => setCurrentTab('recent')}>RECENT</a>
            <a href="#" className={currentTab === 'workplace' ? 'active' : ''} onClick={() => setCurrentTab('workplace')}>WORKPLACE</a>
            <a href="#" className={currentTab === 'policies' ? 'active' : ''} onClick={() => setCurrentTab('policies')}>POLICIES</a>
            <a href="#" className={currentTab === 'law-mandates' ? 'active' : ''} onClick={() => setCurrentTab('law-mandates')}>LAW MANDATES</a>
            <a href="#" className={currentTab === 'internal-committee' ? 'active' : ''} onClick={() => setCurrentTab('internal-committee')}>INTERNAL COMMITEE</a>
          </div>
        </div>
      </div>
      <div className='p-5'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h1>Key Elements of Workplace Sexual Harassment</h1>
            <div className='p-2'></div>
            <div>12 October 2022</div>
            <div className='p-2'></div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.standard dummy text ever since the 1500s.standard dummy text ever.</p>
            <div className='p-2'></div>
            <div className='d-flex align-items-center'><p style={{ marginBottom: '10px' }}>Read</p><RightButton href='/blog/key-elements-of-workplace-sexual-harassment' /></div>
            <div className='p-2'></div>
          </div>
          <div className='col-sm-6'>
            <img src={image15} style={{ maxWidth: '100%' }} />
          </div>
        </div>
      </div>
      <div className='p-5'></div>
      <div style={{ backgroundColor: 'rgba(13, 19, 26, 0.02)' }}>
        <div className='p-3'></div>
        <div className='container'>
          <h3>September 2022 blogs</h3>
          <div className='p-2'></div>
          <div className='row'>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
            <div className='col-sm-3'>
              <img src={frame828} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-2'></div>
              <div style={{ fontSize: 'smaller' }}>12 October 2022 | 3 min read</div>
              <div className='p-2'></div>
              <h5>Impact of inappropriate behaviour</h5>
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className='p-3'></div>
            </div>
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <h5>Legal Resources</h5>
        <div className='p-1' style={{ borderBottom: '1px solid pink' }}></div>
        <div className='p-3'></div>
        <div className='row'>
          <div className='col-sm-3'>
            <img src={group817} style={{ width: '100%', maxWidth: '100%' }} />
          </div>
          <div className='col-sm-3'>
            <div className='small-text'>12 October 2022</div>
            <div className='p-1'></div>
            <div>Sexual harassment of women at workplace (prevention, prohibition & redressal) Act 2013</div>
            <div className='p-1'></div>
            <a href="#" className='text-primary small-text'>E-BOOK</a>
          </div>
          <div className='col-sm-3'>
            <img src={group817} style={{ width: '100%', maxWidth: '100%' }} />
          </div>
          <div className='col-sm-3'>
            <div className='small-text'>12 October 2022</div>
            <div className='p-1'></div>
            <div>Sexual harassment of women at workplace (prevention, prohibition & redressal) Act 2013</div>
            <div className='p-1'></div>
            <a href="#" className='text-primary small-text'>E-BOOK</a>
          </div>
        </div>
      </div>
      <div className='p-3'></div>
      <div className='upcoming-events-section'>
        <div className='container'>
          <div className='p-3'></div>
          <p>Upcoming Events</p>
          <div className='p-1 red-line mb-4'></div>
          <div className='row'>
            <div className='col-sm-6'>
              <h2>Connecting Voice</h2>
              <div className='p-1'></div>
              <h4>A Sasha Circle</h4>
              <div className='text-primary'>CONFERENCE</div>
              <div className='p-1'></div>
              <p>SASHA Events: Come and be a part of SASHA events to share your PoSH related experience or learn something new. Come, be a part of the community.</p>
              <div className='p-1'></div>
              <div className='d-flex gap-1 align-items-center'><b style={{marginTop: '-10px'}}>Register</b><RightButton /></div>
            </div>
            <div className='col-sm-6'>
              <img src={rectangle394} style={{ maxWidth: '100%', width: '100%' }} />
              <div className='p-1'></div>
              <div className='small-text'>Wednesday | 19 August 2022 | 7:30 PM -  8:30PM</div>
              <div className='p-1'></div>
              <div>Guests</div>
              <img src={maskGroup1} />
              <img src={maskGroup2} />
              <img src={maskGroup3} />
              <img src={maskGroup4} />
            </div>
          </div>
        </div>
        <div className='p-3'></div>
      </div>
      <Footer backgroundColor='#0D131A' textColor='white' />
    </div>
  )
}

export default Blog