import React, { useEffect, useState } from 'react'
import NavBar from '../../components/navbar/NavBar';
import './profile.css'
import linkedinBlue from '../../assets/images/linkedin-blue.png';
import downloadIcon from '../../assets/images/download-icon.png';
import Footer from '../../components/footer/footer';
import kanti1 from '../../assets/images/kanti-1.png'
import { getAllMembers, getMemberByNickname } from '../../services/memberService';
import { useParams } from 'react-router-dom';
import { mediaUrl, text } from '../../services/pageFunctions';
import { getPageBySlug } from '../../services/pageService';

const Profile = () => {
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState(null);
  const [page, setPage] = useState(null);
  const [minMember, setMinMember] = useState(0);
  const [maxMember, setMaxMember] = useState(7);
  let { nickname } = useParams();

  useEffect(() => {
    getAllMembers().then(result => {
      setMembers(result.data.data);
      setMaxMember(result.data.data.length);
    });
    getMemberByNickname(nickname).then(result => {
      setMember(result.data.data[0]);
    });
    getPageBySlug('profile').then(result => {
      setPage(result.data.data);
    });
  }, []);

  const onNext = () => {
    setMaxMember(maxMember + 1);
    setMinMember(minMember + 1);
  }
  const onPrevious = () => {
    setMinMember(minMember - 1);
    setMaxMember(maxMember - 1);
  }

  return (
    <div>
      <NavBar />
      <div className='p-4'></div>
      <div className='container'>
        <div className='text-danger'>{text(page, 0)}</div>
        <div className='heading'>{text(page, 1)}</div>
        <div>{text(page, 2)}</div>
        <strong className='text-danger'>{text(page, 3)}</strong>
        <div>{text(page, 4)}</div>
      </div>
      <div className='p-3'></div>
      <div className='container'>
        <div className='text-right'>
          {minMember != 0 ? (<a href='#' className='text-danger' onClick={onPrevious}>&lt;</a>) : <></>}
          {members.length > 8 && maxMember < (members.length - 1) ? (<a href='#' className='text-danger' onClick={onNext}>&gt;</a>) : <></>}
        </div>
        <div className='row'>
          {members.filter((member, i) => i >= minMember && i <= maxMember).map((member, i) => (<div className='col-sm-2' key={i}>
            <div className='rounded bg-dark pt-5 pb-5 mb-3' style={{ backgroundImage: `url('${kanti1}')`, backgroundSize: 'cover' }}><div className='pt-5 pb-5'></div></div>
            <h5 className='stylish'>{member.name}</h5>
            <div>{member.position}</div>
          </div>))}
        </div>
      </div>
      <div className='p-4'></div>
      <div className='container'>
        <img src={mediaUrl(member?.largeImage[0]._id)} style={{ float: 'left', width: '210px', marginRight: '20px' }} />
        <div className='p-5'></div>
        <div style={{ paddingLeft: '40px' }}>
          <div className='bg-dark p-4' style={{ borderRadius: '60px' }}>
            <h3 className='text-white stylish'>{member?.name}</h3>
            <div className='text-white'>{member?.position}</div>
          </div>
        </div>
      </div>
      <div className='p-4'></div>
      <div className='container'>
        <div className='d-flex'>
          <a href={member?.linkedInLink} target='_blank'>
            <img src={linkedinBlue} />
            <div className='text-dark pt-2' style={{ fontSize: 'x-small' }}>Linkedin</div>
          </a>
          <div className='p-4'></div>
          <a href='#'>
            <img src={downloadIcon} />
            <div className='text-dark pt-2' style={{ fontSize: 'x-small' }}>Download</div>
          </a>
        </div>
      </div>
      <div className='container'>
        <div className='p-3'></div>
        <div style={{ lineHeight: '1.75em' }}>{member?.about}</div>
        <div className='p-3'></div>
        <h4 className='stylish ps-3' style={{ borderLeft: '5px solid #ff0000' }}>Work</h4>
        <div className='p-3'></div>
        <div style={{ lineHeight: '1.75em' }}>{member?.work}</div>
        <div className='p-3'></div>
        <h4 className='stylish ps-3' style={{ borderLeft: '5px solid #ff0000' }}>Select Publications</h4>
        <div className='p-3'></div>
        <div style={{ lineHeight: '1.75em' }}>
          <ul>
            {member?.selectPublications.split('\n').map((line, i) => (<li key={i}>{line}</li>))}
          </ul>
          <div className='p-3'></div>
        </div>
        <h4 className='stylish ps-3' style={{ borderLeft: '5px solid #ff0000' }}>Testimonials</h4>
        <div className='p-3'></div>
        <div style={{ lineHeight: '1.75em' }}>{member?.testimonials}</div>
        <div className='p-3'></div>
      </div>
      <div className='p-5'></div>
      <Footer />
    </div>
  )
}

export default Profile