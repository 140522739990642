import React, { useState } from 'react'
import logo from '../../assets/images/logo.png'
import HamburgerIcon from '../hamburger-icon/HamburgerIcon';

const NavBar = () => {
  const [current, setCurrent] = useState('home');

  return (
    <div className='shadow'>
      <div className='container'>
        <nav className="navbar navbar-expand-lg bg-transparent">
          <div className="container-fluid align-items-center justify-content-between">
            <a className="navbar-brand" href="/"><img src={logo} style={{ height: '60px' }} /></a>
            <span className="navbar-toggler border-0" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <HamburgerIcon />
            </span>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href="/home">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/services">Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">Contact Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/blog">Blog</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/learning-center">Learning Center</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default NavBar