import React from 'react'
import './right-button.css'
import rightButton from '../../assets/images/right-button.png'

const RightButton = (props) => {
  return (
    <a href={props.href}>
      <img src={rightButton} className={props.className} />
    </a>
  )
}

export default RightButton