import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import { createTheme, ThemeProvider } from '@mui/material';
import PageNotFound from './pages/page-not-found/page-not-found';
import Profile from './pages/profile/Profile';
import LearningCenter from './pages/learning-center/LearningCenter';
import Services from './pages/services/Services';
import { ViewportProvider } from './hooks/viewport-provider';
import Blog from './pages/blog/Blog';
import Article from './pages/article/Article';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
    ].join(','),
  }
});

function App() {
  return (
    <ViewportProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/home' element={<Home />}></Route>
            <Route path='/about' element={<About />}></Route>
            <Route path='/contact' element={<Contact />}></Route>
            <Route path='/profile/:nickname' element={<Profile />}></Route>
            <Route path='/learning-center' element={<LearningCenter />}></Route>
            <Route path='/services' element={<Services />}></Route>
            <Route path='/blog' element={<Blog />}></Route>
            <Route path='/blog/:slug' element={<Article />}></Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ViewportProvider>
  );
}

export default App;
