const axios = require('axios').default;

const getPageBySlug = async (slug) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/page/slug/${slug}`);
  }
  catch(err) {
    console.log('Fetching page details failed');
    return null;
  }
}

export { getPageBySlug };
